import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
  Container,
  IconButton,
  Hidden,
  Box,
} from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';
import { CardSolution } from 'components/organisms';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { WindMillLoading } from 'react-loadingg';
import SolutionsGrid from './SolutionsGrid';
import { Link } from 'gatsby';

const useStyles = makeStyles(theme => ({
  grid: {
    justifyContent: 'center',
  },
  fontWeight900: {
    fontWeight: 900,
  },
  loadingContainer: {
    marginTop: '30px',
    height: '200px',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  categoryIconButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  themeColors: {
    color: theme.palette.primary.light,
  },
  wordHighlight: {
    color: 'primary',
  },
  verticalAlign: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  loadMore: {
    marginTop: '20px',
    marginBottom: '20px',
    justifyContent: 'center',
  },
  loadingAnimation: {
    position: 'relative',
  },
}));

interface Filter {
  cursor: string;
  sdgs: string[];
  solution_types: string[];
  industries: string[];
  themes: string[];
  stakeholders: string[];
  value_chain_impact: string[];
}

const SolutionsShowcaseSimilar = ({
  className,
  filter,
  title,
  number_options,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const [dataPlot, setDataPlot] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(true);

  const GETSOLUTIONS = gql`
    query faunaFilterSolutions(
      $sdgs: [String]!
      $solution_types: [String]!
      $industries: [String]!
      $themes: [String]!
      $stakeholders: [String]!
      $value_chain_impact: [String]!
      $cursor: String
      $number_options: Int
    ) {
      filterSolutions(
        sdgs: $sdgs
        solution_types: $solution_types
        industries: $industries
        themes: $themes
        stakeholders: $stakeholders
        value_chain_impact: $value_chain_impact
        _cursor: $cursor
        _size: $number_options
      ) {
        after
        before
        data {
          _id
          name
          banner_img
          description
          entity {
            data {
              _id
              name
              geographies {
                data {
                  _id
                  name
                }
              }
            }
          }
          sdgs {
            data {
              _id
              name
              sdg_id
              logo_square_img
            }
          }
          target_industry {
            data {
              _id
              name
            }
          }
          stakeholders {
            data {
              _id
              name
            }
          }
          themes {
            data {
              _id
              name
            }
          }
          solution_types {
            data {
              _id
              name
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GETSOLUTIONS, {
    variables: {
      sdgs: filter.sdgs,
      solution_types: filter.solution_types,
      industries: filter.industries,
      themes: filter.themes,
      stakeholders: filter.stakeholders,
      value_chain_impact: filter.value_chain_impact,
      cursor: null,
      number_options: number_options,
    },
  });

  return (
    <Grid container>
      {data && data.length > 0 ? (
        <>
          <Grid item xs={8}>
            <SectionHeader
              title={title}
              align="left"
              titleVariant="h4"
              titleProps={{ className: classes.fontWeight900 }}
            />
          </Grid>
          <Grid item xs={4}>
            <Link
              to={'/all-solutions'}
              className={clsx(
                'card-category-link__item',
                classes.categoryIconButton,
              )}
              state={filter}
            >
              <Typography className={classes.verticalAlign}>See all</Typography>
              <IconButton className="card-category-link__icon-button">
                <ArrowRightAltIcon
                  className={clsx(
                    'card-category-link__icon',
                    classes.themeColors,
                  )}
                />
              </IconButton>
            </Link>
          </Grid>
          <SolutionsGrid dataPlot={data.filterSolutions.data} />
        </>
      ) : (
        ''
      )}

      <Grid container spacing={0} justify="center">
        <Grid container item xs={12} justify="center">
          {loading ? (
            <>
              <div className={classes.loadingContainer}>
                <WindMillLoading
                  color="green"
                  size="large"
                  style={{ position: 'relative', display: 'inline-block' }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SolutionsShowcaseSimilar;
