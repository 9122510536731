import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  ListItemSecondaryAction,
  Hidden,
} from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Section, Parallax } from 'components/organisms';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  sectionWrapper: {
    height: 250,
    //backgroundColor: 'white',
  },
  textWhite: {
    color: 'black',
    fontWeight: 'bold',
    background: 'white',
    padding: '4px 8px 4px 8px',
    width: 'fit-content',
    //borderRadius: '8px',
  },
  title: {
    fontWeight: 'bold',
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    height: 120,
    width: 120,
    backgroundColor: 'white',
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
    },
  },
  icon: {
    fontSize: 42,
  },
}));

const Hero = ({
  className,
  cover,
  title,
  subtitle,
  logo,
  social,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
      style={{
        backgroundImage: `url(${cover.src})`,
        objectFit: 'cover',
      }}
    >
      <div className={classes.sectionWrapper}>
        <Section className={classes.section}>
          <>
            <List disablePadding>
              <ListItem disableGutters>
                {logo.photo.src ? (
                  <ListItemAvatar classes={{ root: classes.listItemAvatar }}>
                    <Avatar
                      src={logo.photo.src}
                      alt={logo.name}
                      className={classes.avatar}
                    />
                  </ListItemAvatar>
                ) : (
                  ''
                )}
                <ListItemText
                  primary={title}
                  secondary={subtitle}
                  primaryTypographyProps={{
                    className: classes.textWhite,
                    variant: 'h3',
                  }}
                  secondaryTypographyProps={{
                    className: classes.textWhite,
                    variant: 'subtitle1',
                  }}
                />
                <ListItemSecondaryAction>
                  <Hidden smUp>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </Hidden>

                  {social.website ? (
                    <a href={social.website} target="_blank">
                      <IconButton className={classes.socialIcon}>
                        <LanguageIcon className={classes.icon} />
                      </IconButton>
                    </a>
                  ) : (
                    ''
                  )}
                  {social.facebook ? (
                    <a href={social.facebook} target="_blank">
                      <IconButton className={classes.socialIcon}>
                        <FacebookIcon className={classes.icon} />
                      </IconButton>
                    </a>
                  ) : (
                    ''
                  )}
                  {social.instagram ? (
                    <a href={social.instagram} target="_blank">
                      <IconButton className={classes.socialIcon}>
                        <InstagramIcon className={classes.icon} />
                      </IconButton>
                    </a>
                  ) : (
                    ''
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </>
        </Section>
      </div>
    </div>
  );
};

export default Hero;
