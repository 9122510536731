import React from 'react';
import { Grid } from '@material-ui/core';
import { CardSolution } from 'components/organisms';

const SolutionsGrid = ({ dataPlot, ...rest }: SolutionsGrid): JSX.Element => {
  return (
    <>
      <Grid container spacing={0}>
        {dataPlot.map(node => {
          const item = {
            id: node._id,
            images: [{ src: node.banner_img, alt: node.name }],
            name: node.name,
            description: node.description,
            industries: node.target_industry.data,
            sdgs: node.sdgs.data,
            stakeholders: node.stakeholders.data,
            themes: node.themes.data,
            solution_types: node.solution_types.data,
            entity: node.entity.data,
          };
          return (
            <Grid key={node._id} item xs={12} sm={6} md={4}>
              <CardSolution {...item} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SolutionsGrid;
