import React, { useEffect } from 'react';
import {
  makeStyles,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  ListItemText,
  ListItemAvatar,
  Avatar,
  useTheme,
  Chip,
  Hidden,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { Section, SectionAlternate } from 'components/organisms';
import { SectionHeader } from 'components/molecules';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { WindMillLoading } from 'react-loadingg';
import { SolutionFeatures, Hero, SolutionsShowcaseSimilar } from './components';
import { Link } from 'gatsby';
import { deduplicateArray } from 'helpers/helpers-array.js';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  loadingContainer: {
    marginTop: '30px',
    height: '400px',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  inline: {
    display: 'inline',
  },
  sdgContainer: {
    height: '60px',
    width: '60px',
    margin: '10px',
  },
  sdgImage: {
    objectFit: 'contain',
  },
  fontWeight900: {
    fontWeight: 900,
  },
  dividers: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  chipArea: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

interface Filter {
  cursor: string;
  sdgs: string[];
  solution_types: string[];
  industries: string[];
  themes: string[];
  stakeholders: string[];
  value_chain_impact: string[];
}

const DetailedSolutionPage = ({ solution_id }): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const empty_filter = {
    cursor: null,
    sdgs: [],
    solution_types: [],
    industries: [],
    themes: [],
    stakeholders: [],
    value_chain_impact: [],
  };

  const [filterData, setFilterData] = React.useState<Filter>(empty_filter);

  const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(true);

  const GETSOLUTION = gql`
    query faunaGetSolutionDetails($query_id: ID!) {
      findSolutionsByID(id: $query_id) {
        _id
        name
        banner_img
        logo_img
        description
        value_chain_impact
        scope_of_operations
        website_url
        entity {
          data {
            _id
            name
            status
            number_employees
            logo_img
            instagram_url
            facebook_url
            website_url
            geographies {
              data {
                _id
                name
              }
            }
          }
        }
        sdgs {
          data {
            _id
            name
            sdg_id
            logo_square_img
          }
        }
        target_industry {
          data {
            _id
            name
          }
        }
        stakeholders {
          data {
            _id
            name
          }
        }
        themes {
          data {
            _id
            name
          }
        }
        solution_types {
          data {
            _id
            name
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GETSOLUTION, {
    variables: {
      query_id: solution_id,
    },
  });

  useEffect(() => {
    if (data && shouldUpdate) {
      setFilterData({
        ...empty_filter,
        solution_types: data.findSolutionsByID.solution_types.data.map(
          (item, index) => item._id,
        ),
        sdgs: data.findSolutionsByID.sdgs.data.map((item, index) => item._id),
        industries: data.findSolutionsByID.target_industry.data.map(
          (item, index) => item._id,
        ),
      });
      setShouldUpdate(false);
    }
  }),
    [data];

  const filtersSimilarSolutions: Filter = {
    cursor: null,
    sdgs: [],
    solution_types: filterData.solution_types,
    industries: filterData.industries,
    themes: [],
    stakeholders: [],
    value_chain_impact: [],
  };

  const filtersSolutionsSameGoals: Filter = {
    cursor: null,
    sdgs: filterData.sdgs, //data.findSolutionsByID.sdgs.data.map((item, index) => item._id),
    solution_types: [],
    industries: [],
    themes: [],
    stakeholders: [],
    value_chain_impact: [],
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <Section>
          <Grid container spacing={0} justify="center">
            <Grid xs={3} sm={4} />
            <Grid container item xs={6} sm={4} justify="center">
              <div className={classes.loadingContainer}>
                <WindMillLoading
                  color="green"
                  size="large"
                  style={{ position: 'relative', display: 'inline-block' }}
                />
              </div>
            </Grid>
            <Grid xs={3} sm={4} />
          </Grid>
        </Section>
      ) : (
        <>
          <Hero
            cover={{
              src: data.findSolutionsByID.banner_img,
              srcSet: data.findSolutionsByID.banner_img,
            }}
            title={data.findSolutionsByID.name}
            subtitle={
              data.findSolutionsByID.entity.data[0] ? (
                <Link
                  to={`/details/entity/${data.findSolutionsByID.entity.data[0]._id}`}
                >
                  By {data.findSolutionsByID.entity.data[0].name}
                </Link>
              ) : (
                ''
              )
            }
            logo={{
              photo: {
                src: data.findSolutionsByID.logo_img
                  ? data.findSolutionsByID.logo_img
                  : data.findSolutionsByID.entity.data[0]
                  ? data.findSolutionsByID.entity.data[0].logo_img
                  : '',
                srcSet: data.findSolutionsByID.logo_img
                  ? data.findSolutionsByID.logo_img
                  : data.findSolutionsByID.entity.data[0]
                  ? data.findSolutionsByID.entity.data[0].logo_img
                  : '',
              },
              name: data.findSolutionsByID.entity.data[0]
                ? data.findSolutionsByID.entity.data[0].name
                : null,
            }}
            social={{
              facebook: data.findSolutionsByID.entity.data[0]
                ? data.findSolutionsByID.entity.data[0].facebook_url
                : null,
              website: data.findSolutionsByID.website_url
                ? data.findSolutionsByID.website_url
                : data.findSolutionsByID.entity.data[0].website_url,
              instagram: data.findSolutionsByID.entity.data[0]
                ? data.findSolutionsByID.entity.data[0].instagram_url
                : null,
            }}
          />
          <Section>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <SectionHeader
                    title={`About ${data.findSolutionsByID.name}`}
                    align="left"
                    titleVariant="h5"
                    titleProps={{ className: classes.fontWeight900 }}
                  />
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    component="span"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data.findSolutionsByID.description,
                      }}
                    />
                  </Typography>
                </Grid>
                <Hidden smUp>
                  {' '}
                  <Grid item xs={12}>
                    <Divider classes={{ root: classes.dividers }} />
                  </Grid>
                </Hidden>
                <Grid container item xs={12} sm={6}>
                  <SectionHeader
                    title={`Impact Classification`}
                    align="left"
                    titleVariant="h5"
                    titleProps={{ className: classes.fontWeight900 }}
                  />
                  <Divider classes={{ root: classes.dividers }} />
                  <Grid item xs={12} sm={6}>
                    <SolutionFeatures
                      image_url={`/icon/industries.png`}
                      name={`Industries`}
                      attributes={data.findSolutionsByID.target_industry.data}
                      color="pastelBlue"
                      filterKey="industries"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SolutionFeatures
                      image_url={`/icon/scope_of_operations.png`}
                      name={`Scope of Operations`}
                      attributes={[
                        {
                          name: data.findSolutionsByID.scope_of_operations,
                        },
                      ]}
                      color="pastelRed"
                      filterKey="scope_of_operations"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SolutionFeatures
                      image_url={`/icon/stakeholders.png`}
                      name={`Stakeholders`}
                      attributes={data.findSolutionsByID.stakeholders.data}
                      color="pastelGreen"
                      filterKey="stakeholders"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SolutionFeatures
                      image_url={`/icon/value_chain_impact.png`}
                      name={`Step of the value chain `}
                      attributes={[
                        {
                          name: data.findSolutionsByID.value_chain_impact,
                        },
                      ]}
                      color="pastelYellow"
                      filterKey="value_chain_impact"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider classes={{ root: classes.dividers }} />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <SectionHeader
                    title={`Themes they support`}
                    align="left"
                    titleVariant="h5"
                    titleProps={{ className: classes.fontWeight900 }}
                  />
                  <div className={classes.chipArea}>
                    {data.findSolutionsByID.themes.data.map((item, index) => (
                      <Link
                        to={'/all-solutions'}
                        state={{ themes: [`${item._id}`] }}
                      >
                        <Chip
                          key={index}
                          color="textPrimary"
                          label={item.name}
                        />
                      </Link>
                    ))}
                  </div>
                </Grid>
                <Hidden smUp>
                  {' '}
                  <Grid item xs={12}>
                    <Divider classes={{ root: classes.dividers }} />
                  </Grid>
                </Hidden>
                <Grid item container xs={12} sm={6}>
                  <SectionHeader
                    title={`Social development goals`}
                    align="left"
                    titleVariant="h5"
                    titleProps={{ className: classes.fontWeight900 }}
                  />
                  {data.findSolutionsByID.sdgs.data.map((item, index) => (
                    <Link
                      to={'/all-solutions'}
                      state={{ sdgs: [`${item._id}`] }}
                    >
                      <div key={index} className={classes.sdgContainer}>
                        <Image
                          src={item.logo_square_img}
                          srcSet={item.logo_square_img}
                          alt="Social development goals"
                          className={classes.sdgImage}
                        />
                      </div>
                    </Link>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Section>
          <SectionAlternate>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SolutionsShowcaseSimilar
                  title={`Solutions similar to ${data.findSolutionsByID.name}`}
                  filter={filtersSimilarSolutions}
                  number_options={3}
                />
              </Grid>
            </Grid>
          </SectionAlternate>
          <Section>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SolutionsShowcaseSimilar
                  title="Supporting the same development goals"
                  filter={filtersSolutionsSameGoals}
                  number_options={3}
                />
              </Grid>
            </Grid>
          </Section>
        </>
      )}
    </div>
  );
};

export default DetailedSolutionPage;
