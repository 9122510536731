// Dynamic path for an entity or product page detail

import React from 'react';
import { Router } from '@reach/router';
import DetailedEntityPage from 'views/DetailedEntityPage';
import DetailedSolutionPage from 'views/DetailedSolutionPage';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

export default function Details(props) {
	return (
		<Router basepath={`/details`}>
			<WithLayout
				component={DetailedEntityPage}
				layout={Main}
				path={`/entity/:entity_id`}
				location={props.location}
			/>
			<WithLayout
				component={DetailedSolutionPage}
				layout={Main}
				path={`/solution/:solution_id`}
				location={props.location}
			/>
		</Router>
	);
}
